import app_store from './app_store.svg'
import google_play from './google_play.svg'

import achievement from './screenshots/achievement.png'
import search from './screenshots/search.png'
import weapon from './screenshots/weapon.png'

import wowdb from './wowdb.svg'

const screenshots = {
  achievement,
  search,
  weapon
}

export { app_store, google_play, wowdb, screenshots }
